/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap'); */

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  color: #333;
  /* font-family: 'Poppins'; */
  font-size: 100;
  background: #fff;
}

a {
  scroll-behavior: smooth;
}
.hero {
  /* background: 
    linear-gradient(0deg, rgba(0, 117, 227, 0.08), rgba(0, 117, 227, 0.08)),
    linear-gradient(180deg, rgba(0, 0, 0, 0.56) 0%, rgba(0, 0, 0, 0) 100%),
    url('/home-banner-1.jpg'); */
  background: linear-gradient(0deg, rgba(0, 117, 227, 0.08), rgba(0, 117, 227, 0.08)),
    linear-gradient(180deg, rgba(0, 0, 0, 0.56) 0%, rgba(0, 0, 0, 0) 100%),
    url('../../public/home-banner.jpeg');
  background-size: cover;
  background-position: center;
}
.bottom-hero {
  background: linear-gradient(0deg, rgba(0, 117, 227, 0.08), rgba(0, 117, 227, 0.08)),
    linear-gradient(180deg, rgba(0, 0, 0, 0.56) 0%, rgba(0, 0, 0, 0) 100%),
    url('../../public/bottom-banner.jpg');
  background-size: cover;
  background-position: bottom;
}

.choose-loan-right {
  background: url('../../public/happy-man.png') no-repeat;
  background-size: 44rem;
  background-position-y: -10px;
  position: relative;
  top: -30px;
  transform: scale(1.1);
}
.call-to-action {
  background-image: url('../../public/overview-bg.jpg');
  background-size: cover;
  background-position: center;
  border-radius: 5px;
}

.blog-hero-section .hero-img {
  /* background: url('/blog.jpg'); */
  position: relative;
  width: 100%;
  background-size: cover;
  background-position: left;
  overflow: hidden;
}
.blog-hero-section .hero-img img {
  background-size: cover;
  width: 100%;
}
.blog-img {
  top: 0;
}
.blog-hero-content {
  display: grid;
  align-items: center;
  align-content: center;
  padding: 0 1rem;
  position: relative;
}
.blog-thumb img {
  object-fit: cover;
}
.grey-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(120deg, #2d3748 0%, transparent 180%);
}
.blog-desc {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.blog-d-main .blog-d::before {
  content: '.';
  top: -3px;
  position: relative;
  margin: 0 8px;
}
.blog-d-main .blog-d:first-child::before {
  content: none;
}
.profile-img {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  color: #667eea;
  -webkit-text-decoration: none;
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.profile-img-bg {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  position: relative;
  z-index: 2;
  text-align: center;
  margin-bottom: 1.0499999999999998rem;
}
.icons-img-bg {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../public/icons-img.png');
  background-size: 8rem;
  position: absolute;
  opacity: 0.15;
  top: 0;
  left: 0;
}
.profile-bg-color {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  width: 10.5rem;
  height: 100%;
  max-width: 150px;
  border-radius: 9999px;
  position: absolute;
  -webkit-transform: translate(-50%) scale(0.98);
  -ms-transform: translate(-50%) scale(0.98);
  transform: translate(-50%) scale(0.98);
  left: 50%;
  top: 1.0499999999999998rem;
  background-color: #667eea;
}
.profile-img-style {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  width: 10.5rem;
  height: 100%;
  max-width: 150px;
  border-radius: 9999px;
  position: absolute;
  z-index: 99;
  left: 50%;
  -webkit-transform: translate(-50%);
  -ms-transform: translate(-50%);
  transform: translate(-50%);
  margin-top: -0.35rem;
  margin-left: -0.7rem;
  box-shadow: 0.7rem 1.4rem #e2e8f0;
}
.image-wrapper {
  position: relative;
  overflow: hidden;
  width: 150px;
  height: 150px;
  margin: 0 auto !important;
  border-radius: 0 0 9999px 9999px;
}
.social-links a:hover svg {
  color: #fff;
}

/*--------------Works-Page-Start------------*/

.cc-section {
  position: relative;
  z-index: 5;
  overflow: hidden;
  padding-top: 70px;
  padding-bottom: 70px;
  background-color: #fff;
  opacity: 1;
  -o-object-fit: fill;
  object-fit: fill;
}
.cc-container.wider {
  max-width: 1240px;
}
.cc-container {
  position: relative;
  z-index: 5;
  max-width: 970px;
  height: 80%;
  max-width: 92%;
  min-height: 140px;
  margin-right: auto;
  margin-left: auto;
}
.cc-wave-holder {
  position: relative;
  margin-top: 60px;
  margin-bottom: 50px;
}
.cc-wave-img {
  position: relative;
  z-index: 1;
  width: 100%;
}
.cc-steps-item {
  position: absolute;
  left: -5px;
  top: -15px;
  right: auto;
  bottom: auto;
  z-index: 5;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 38px;
  height: 38px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 0 16px 0 rgb(0 0 0 / 5%);
  -webkit-transition:
    color 0.2s,
    background-color 0.2s;
  transition:
    color 0.2s,
    background-color 0.2s;
  color: #1f51ff;
  font-size: 14px;
  line-height: 1.3em;
  text-decoration: none;
  cursor: pointer;
}
.w-inline-block {
  max-width: 100%;
}
.cc-steps-item-note {
  position: absolute;
  left: 40px;
  top: auto;
  right: auto;
  bottom: 45px;
  width: 120px;
  color: #303030;
  text-align: center;
}
.cc-steps-item.step2 {
  left: 22%;
  top: auto;
  right: auto;
  bottom: -5%;
}
.cc-steps-item-note.step2 {
  left: 20px;
  top: 45px;
  right: auto;
  bottom: auto;
  width: 150px;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  text-align: center;
}
.cc-steps-item.step3 {
  left: 39%;
}
.cc-steps-item-note.step3 {
  left: 50%;
  bottom: 45px;
  width: 110px;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  text-align: center;
}
.cc-steps-item-note {
  position: absolute;
  left: 40px;
  top: auto;
  right: auto;
  bottom: 45px;
  width: 120px;
  color: #303030;
  text-align: center;
}
.cc-steps-item.step4 {
  left: 58%;
  top: auto;
  right: auto;
  bottom: -15%;
}
.cc-steps-item-note.step4 {
  left: 50%;
  top: 45px;
  right: auto;
  bottom: auto;
  width: 150px;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  text-align: center;
}
.cc-step5 {
  position: absolute;
  left: auto;
  top: -30%;
  right: 8%;
  bottom: auto;
  z-index: 5;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 140px;
  height: 140px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  background-color: #1f51ff;
  box-shadow: 0 0 16px 0 rgb(0 0 0 / 5%);
  -webkit-transition:
    color 0.2s,
    background-color 0.2s;
  transition:
    color 0.2s,
    background-color 0.2s;
  color: #fff;
  font-size: 14px;
  line-height: 1.5em;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}
.cc-steps-item:hover {
  background-color: #1f51ff;
  color: #fff;
}
@media screen and (max-width: 767px) {
  .cc-wave-img {
    display: none;
  }
  .cc-wave-holder {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .cc-steps-item {
    position: relative;
    left: 50%;
    top: auto;
    margin-bottom: 50px;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
  .cc-steps-item-note {
    left: auto;
    top: 50%;
    right: 120%;
    bottom: auto;
    width: 120px;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    text-align: right;
  }
  .cc-steps-item.step2 {
    left: 50%;
    bottom: auto;
  }
  .cc-steps-item-note.step2 {
    left: 120%;
    top: 50%;
    right: auto;
    bottom: auto;
    text-align: left;
  }
  .cc-steps-item.step3 {
    left: 50%;
  }
  .cc-steps-item-note.step3 {
    left: auto;
    top: 50%;
    right: 120%;
    bottom: auto;
    width: 120px;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    text-align: right;
  }
  .cc-steps-item.step4 {
    left: 50%;
    bottom: auto;
  }
  .cc-steps-item-note.step4 {
    left: 120%;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    text-align: left;
  }
  .cc-step5 {
    position: relative;
    left: 50%;
    top: auto;
    right: auto;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
  .cc-steps-dasheline {
    position: absolute;
    left: 50%;
    top: 2%;
    right: auto;
    bottom: 2%;
    display: block;
    width: 1px;
    border-left: 2px dashed #1f51ff;
  }
}
.swiper-button-next:after,
.swiper-button-prev:after {
  color: #fff;
  font-size: 12px !important;
}
.swiper-button-next,
.swiper-button-prev {
  width: 22px !important;
  height: 22px !important;
  background: #303030;
  border-radius: 50%;
  opacity: 0.7;
}
.swiper-button-next:hover,
.swiper-button-prev:hover {
  opacity: 1;
}
.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: 1px !important;
}
.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: 1px !important;
}
/*--------------Works-Page-End--------------*/

.testimonials-home .swiper-button-next:after,
.testimonials-home .swiper-button-prev:after {
  color: #1f51ff;
  font-size: 20px !important;
}
.testimonials-home .swiper-button-next,
.testimonials-home .swiper-button-prev {
  background: transparent;
  opacity: 1;
}
.swiper-pagination-horizontal {
  text-align: center;
  margin-top: 30px;
}
.swiper-pagination-horizontal span {
  cursor: pointer;
  font-size: 0;
  margin: 0 6px;
}
.swiper-pagination-horizontal span::before {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: rgb(204, 204, 204);
  display: inline-block;
}
.swiper-pagination-horizontal span.timeline-icon-active::before {
  background: #1f51ff;
  padding: 2px;
  box-shadow:
    0 0 0 5px #ffffff,
    0 0 0 6px #1f51ff;
}

.testimonials-home h2 {
  line-height: 56px;
}

.hero-overlay {
  /* background: rgba(0, 0, 0, 0.60); */
}

.common-input-text {
  @apply p-4 
    mt-3 
    w-full 
    border
    border-gray-300
    shadow-md
    hover:shadow-xl
    focus:outline-none
    focus:ring-2
    rounded-md
    focus:ring-blue-700;
}

.input-text {
  @apply p-3  
    w-full 
    border
    border-gray-300
    focus:outline-none
    focus:ring-2
    rounded-md
    focus:ring-blue-700;
}

.common-button {
  @apply py-4 
    px-10 
    w-full 
    bg-blue-700
    hover:bg-blue-600 
    focus:bg-blue-600 
    text-white 
    rounded-md 
    shadow-lg
    text-lg;
}

.common-button[disabled] {
  @apply py-4 
    px-10 
    w-full 
    bg-blue-300
    text-white 
    rounded-md 
    shadow-lg
    text-lg;
}

.common-radio-rec-ghost-btn {
  @apply text-blue-800 
  bg-white 
  border-2
   border-blue-800
    py-4
    px-10
    block
    w-full
    rounded-md
    hover:shadow-xl
    text-lg;
}

.prefix {
  margin-right: -10px;
  position: absolute;
}

.my-input {
  padding-left: 5px;
}
.form-label {
  display: block !important;
}

.error {
  @apply text-red-600 
  text-xs mt-2;
}

label input {
  margin-right: 5px;
}

.selected {
  @apply border-4
    border-blue-700
    bg-blue-100;
}
.chart-custom-tooltip {
  background-color: #fff;
  border: rgb(101, 98, 98) 1px solid;
  padding: 10px;
  font-size: 14px;
  width: 300px;
  display: block;
}
.chart-custom-tooltip p {
  line-height: 30px;
}
.chart-custom-tooltip .label {
  font-weight: 600;
  display: inline-block;
  width: 150px;
}
.chart-custom-tooltip .value {
  text-align: right;
  display: inline-block;
  width: 100px;
}

.ant-btn-primary:not([disabled]):not(.ant-btn-dangerous) {
  @apply bg-blue-700;
}
.ant-btn-primary:not([disabled]):hover {
  @apply bg-blue-600;
}

.ant-input-outlined:not([disabled]) {
  border-color: #d9d9d9;
}

.ant-input:not([disabled]) {
  padding: 4px 11px;
  font-size: 0.875rem;
  line-height: 1.5714285714285714;
  border-radius: 6px;
}

.ant-form-item-explain-error {
  font-size: 0.75rem;
  margin-top: 2px;
}

/* css to change the background and text color of ant design notification */

.ant-notification-notice,
.ant-notification-notice-error {
  background-color: #202020 !important;
  border-radius: 8px;
}
.ant-notification-notice-message,
.ant-notification-notice-close {
  color: white !important;
}
.ant-notification-notice-description {
  color: #aaa !important;
}

@layer components {
  .application-question {
    @apply text-[1.625rem] font-semibold mb-12 text-center;
  }

  .application-form-container {
    @apply grid gap-8 w-[345px] mx-auto;
  }

  .dashboard-add-items-btn {
    @apply rounded border border-blue-500 px-2 py-1 text-blue-500 font-semibold ml-auto block text-xs hover:bg-blue-500 hover:text-white transition ease-in;
  }
}
